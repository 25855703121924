export default [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('@/views/Dashboard.vue'),
    meta: {
      pageTitle: 'dashboard',
      resource: 'dashboard',
      action: 'view',
    },
  },
  {
    path: '/account-management',
    name: 'account-management.account-overview',
    component: () => import('@/views/AccountManagement/Accounts.vue'),
    meta: {
      pageTitle: 'account_management',
      breadcrumb: [
        {
          text: 'account_overview',
          active: true,
        },
      ],
      resource: 'account_overview',
      action: 'view',
    },
  },
  {
    path: '/account-management/partner-info',
    name: 'account-management.partner-info',
    component: () => import('@/views/AccountManagement/PartnerInfo.vue'),
    meta: {
      pageTitle: 'account_management',
      breadcrumb: [
        {
          text: 'partner_info',
          active: true,
        },
      ],
      resource: 'account_overview',
      action: 'view',
    },
  },
  {
    path: '/report/game-history',
    name: 'report.game-history',
    component: () => import('@/views/Report/GameHistory.vue'),
    meta: {
      pageTitle: 'report',
      breadcrumb: [
        {
          text: 'game_history',
          active: true,
        },
      ],
      resource: 'report',
      action: 'game_history',
    },
  },
  {
    path: '/report/bet-transactions',
    name: 'report.bet-transactions',
    component: () => import('@/views/Report/BetTransactions.vue'),
    meta: {
      pageTitle: 'report',
      breadcrumb: [
        {
          text: 'bet_transactions',
          active: true,
        },
      ],
      resource: 'report',
      action: 'bet_transactions',
    },
  },
  {
    path: '/report/transactions',
    name: 'report.transactions',
    component: () => import('@/views/Report/Transactions.vue'),
    meta: {
      pageTitle: 'report',
      breadcrumb: [
        {
          text: 'transactions',
          active: true,
        },
      ],
      resource: 'report',
      action: 'game_transactions',
    },
  },
  {
    path: '/report/win-lose',
    name: 'report.win-lose',
    component: () => import('@/views/Report/WinLose.vue'),
    meta: {
      pageTitle: 'report',
      breadcrumb: [
        {
          text: 'win_lose',
          active: true,
        },
      ],
      resource: 'report',
      action: 'win_lose',
    },
  },
  {
    path: '/report/jackpot',
    name: 'report.jackpot',
    component: () => import('@/views/Report/Jackpot.vue'),
    meta: {
      pageTitle: 'jackpot',
      breadcrumb: [
        {
          text: 'jackpot',
          active: true,
        },
      ],
      resource: 'report',
      action: 'jackpot',
    },
  },
  {
    path: '/report/fund-transactions',
    name: 'report.fund_transactions',
    component: () => import('@/views/Report/FundTransactions.vue'),
    meta: {
      pageTitle: 'report',
      breadcrumb: [
        {
          text: 'fund_transactions',
          active: true,
        },
      ],
      resource: 'report',
      action: 'transactions',
    },
  },
  {
    path: '/report/balance',
    name: 'report.balance',
    component: () => import('@/views/Report/Balance.vue'),
    meta: {
      pageTitle: 'report',
      breadcrumb: [
        {
          text: 'balance',
          active: true,
        },
      ],
      resource: 'report',
      action: 'balance',
    },
  },
  {
    path: '/report/online-users',
    name: 'report.online_users',
    component: () => import('@/views/Report/OnlineUsers.vue'),
    meta: {
      pageTitle: 'report',
      breadcrumb: [
        {
          text: 'online_users',
          active: true,
        },
      ],
      resource: 'report',
      action: 'online_users',
    },
  },
  {
    path: '/audit/banned-list',
    name: 'audit.banned_list',
    component: () => import('@/views/Audit/BannedList.vue'),
    meta: {
      pageTitle: 'audit',
      breadcrumb: [
        {
          text: 'banned_list',
          active: true,
        },
      ],
      resource: 'audit',
      action: 'banned_list',
    },
  },
  {
    path: '/audit/ban-history-list',
    name: 'audit.ban_history_list',
    component: () => import('@/views/Audit/BanHistoryList.vue'),
    meta: {
      pageTitle: 'audit',
      breadcrumb: [
        {
          text: 'ban_history_list',
          active: true,
        },
      ],
      resource: 'audit',
      action: 'ban_history_list',
    },
  },
  {
    path: '/audit/unsettled',
    name: 'audit.unsettled',
    component: () => import('@/views/Audit/Unsettled.vue'),
    meta: {
      pageTitle: 'audit',
      breadcrumb: [
        {
          text: 'unsettled',
          active: true,
        },
      ],
      resource: 'audit',
      action: 'view',
    },
  },
  {
    path: '/setting/change-password',
    name: 'setting.change-password',
    component: () => import('@/views/Setting/ChangePassword.vue'),
    meta: {
      pageTitle: 'setting',
      breadcrumb: [
        {
          text: 'change_password',
          active: true,
        },
      ],
      resource: 'setting',
      action: 'change_password',
    },
  },
  {
    path: '/setting/log',
    name: 'setting.log',
    component: () => import('@/views/Setting/Log.vue'),
    meta: {
      pageTitle: 'setting',
      breadcrumb: [
        {
          text: 'log',
          active: true,
        },
      ],
      resource: 'setting',
      action: 'log',
    },
  },
  {
    path: '/setting/seamless-log',
    name: 'setting.seamless_log',
    component: () => import('@/views/Setting/SeamlessLog.vue'),
    meta: {
      pageTitle: 'setting',
      breadcrumb: [
        {
          text: 'seamless_log',
          active: true,
        },
      ],
      resource: 'setting',
      action: 'seamless_log',
    },
  },
  {
    path: '/setting/avatar-activities',
    name: 'setting.avatar_activities',
    component: () => import('@/views/Setting/AvatarActivities.vue'),
    meta: {
      pageTitle: 'setting',
      breadcrumb: [
        {
          text: 'avatar_activities',
          active: true,
        },
      ],
      resource: 'setting',
      action: 'avatar_activities',
    },
  },
  {
    path: '/setting/table-limit',
    name: 'setting.table_limit',
    component: () => import('@/views/Setting/TableLimit.vue'),
    meta: {
      pageTitle: 'setting',
      breadcrumb: [
        {
          text: 'table_limit',
          active: true,
        },
      ],
      resource: 'setting',
      action: 'table_limit',
    },
  },
]
